@import url("../fonts/satoshi.css");

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: "Satoshi-Regular";
  overflow-x: hidden !important;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
/* landing page */
.landing-bg {
  background-image: url("../Assets/images/landing-bg.webp");
  min-height: 100vh;
  padding-bottom: 10px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.subscrption-content {
  max-width: 375px;
  margin: auto;
  padding: 0 5px 10px;
}

.subscrption-content .head-number {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-family: "Satoshi-Medium";
  font-size: 15px;
}

.subscrption-content .code-number {
  display: flex;
  border: none;
  align-items: center;
  gap: 10px;
  width: 85%;
  margin: 5px auto 0;
}
.subscrption-content .code .fixed-cnt {
  font-size: 15px;
  border-radius: 6px;
  padding: 9px 8px;
  background: rgba(255, 255, 255, 0.35);
  border: 2px solid rgba(255, 255, 255, 0.35);
  color: #fff;
}
.lp-loader {
  border: 2px solid rgba(255, 255, 255, 0.35);
  padding: 14px 8px;
  border-radius: 10px;
  color: #fff !important;
  text-align: center;
  font-weight: 600;
  width: 85%;
  font-size: 15px;
  cursor: pointer;
  background: linear-gradient(107.99deg, #2429b7 -34.66%, #862bff 89.7%), linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
  margin-top: 10px;
}

.subscrption-content .form {
  border-radius: 30px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.15);
  padding: 30px 20px;
  backdrop-filter: blur(9px);
  text-align: center;
  border: 1px solid rgba(153, 151, 255, 1);
  position: relative;
  margin-top: -9px;
}

.subscrption-content .form img.dayf {
  position: absolute;
  left: -30px;
  width: 30%;
  top: -60px;
}

.input-content .text {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: "Satoshi-Medium";
}
.subscrption-content .subscrption-image {
  text-align: center;
  position: relative;
  z-index: 2;
}
.subscrption-content .subscrption-image img{
  width: 70%;
  margin: auto;
}
.subscrption-content .input input {
  background: rgba(255, 255, 255, 0.35);
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 16px;
  border: 2px solid rgba(255, 255, 255, 0.35);
  color: #fff !important;
}

.subscrption-content .input input::placeholder {
  color: #fff;
}
.subscrption-content .form .error {
  color: #e91414;
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}

.subscrption-content button {
  border: 2px solid rgba(255, 255, 255, 0.35);
  padding: 14px 8px;
  border-radius: 10px;
  color: #fff !important;
  text-align: center;
  font-weight: 600;
  width: 85%;
  font-size: 15px;
  cursor: pointer;
  background: linear-gradient(107.99deg, #2429b7 -34.66%, #862bff 89.7%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35));
  margin-top: 10px;
}

.subscrption-content button:hover {
  opacity: 0.8;
  transition: 0.2s ease-in-out;
}
.subscrption-content .condition a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
}
.subscrption-content .condition a:hover {
  opacity: 0.8;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

/* otp */
.otp-inputs {
  display: flex;
  justify-content: space-evenly;
  margin: 7px 0 10px 0px;
}

.otp-inputs input {
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  background: rgba(50, 50, 54, 0.5);
  border: 1px solid rgba(204, 204, 204, 1);
  color: #fff !important;
}

.otp-inputs input:focus {
  border: 2px solid rgba(101, 94, 214, 1);
}

.resend-code {
  text-align: center;
  font-size: 14px;
}

.resend-code .timer-text span {
  color: #7978f1;
  font-weight: 700;
  cursor: pointer;
}

.resend-code .timer-text {
  color: #fff;
}

.resend-code .timer-text span:hover {
  text-decoration: underline;
}

.resend-code a {
  font-size: 16px;
}

.not-received .timer-text {
  font-weight: 700;
}
.not-received {
  color: #fff;
}
.price-points {
  color: #fff;
  font-size: 12px;
  margin: 15px 0 6px 0;
}
